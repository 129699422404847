<template>
    <div class="tips">
        请复制网址到电脑端使用浏览器打开并访问系统(建议使用谷歌浏览器)
       <div class="url">
           {{url}}
       </div>
    </div>
</template>
<script>
    export default{
        data(){
            return {
                url:''
            }
        },
        created(){
            this.url = location.protocol + '//' + location.host
            console.log(location.protocol + '//' + location.host)
        }
    }
</script>
<style lang="less" scoped>
    .tips{
        padding:120px 0;
        text-align: center;
        font-size: 24px;
    }
    .url{
        margin: 60px;
        font-size: 30px;
    }
</style>